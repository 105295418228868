












































































































/* eslint-disable @typescript-eslint/camelcase */
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({
    name: 'c-agr-edit'
})
export default class CAgreementEdit extends Vue {
    @Prop({
        required: true,
        default: null
    })
    private agrProp!: any; // текущий редактируемый статус

    @Prop({
        required: true,
        default: null
    })
    private statusProp!: any[]; // список всех статусов

    @Prop({
        required: true,
        default: null
    })
    private curMode!: any; // текущий режим

    @Prop({
        required: false,
        default: null
    })
    private addedCode!: any | null; // если вставляется строка в середину

    private operBase: any[] = [];
    private curOper: any = [];
    private status: any = { id: -1, code: null, name_ru: '', name_kk: null, name_en: null, btn_name_ru: '', btn_name_kk: null, btn_name_en: null };
    private saveDisabled = false;

    private get operLst() {
        const result: any[] = [];
        for (const el of this.operBase){
            result.push(this.setNameLang(el, 'code'));
        }
        return result;
    }

    // ------------- шаги вперёд назад
    private get statusLst() {
        const result = [];
        for (const el of this.statusProp) {
            if (this.status.id !== el.id) {
                result.push(this.setNameLang(el, 'code'));
            }
        }
        return result;
        /* if (this.status.id >= 0) {
            return this.statusProp.filter((item: any) => {
                return item.id !== this.status.id;
            });
        }
        return this.statusProp; */
    }

    private curStepForward: any[] = [];
    private curStepBack: any[] = [];
    // -------------

    private mounted() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        this.loadOper();
        this.$watch('agrProp', that.chgAgrProp);
        this.$watch('addedCode', function () {
            if (that.status !== null && that.status.id === -1) {
                that.status.code = that.addedCode;
            }
        });
    }

    private chgAgrProp() {
        this.saveDisabled = false;
        this.curOper = [];
        this.curStepForward = [];
        this.curStepBack = [];
        if (this.agrProp === null || this.agrProp.id < 0) {
            this.status = { id: -1, code: null, name_ru: '', name_kk: null, name_en: null, btn_name_ru: '', btn_name_kk: null, btn_name_en: null };
            if (this.agrProp !== null && this.statusProp !== null && this.statusProp.length > 0) { this.status.code = this.statusProp[this.statusProp.length - 1].code + 1; }
            if (this.addedCode !== null) { this.status.code = this.addedCode; }
            return;
        }
        this.status = { id: this.agrProp.id, code: this.agrProp.code, name_ru: this.agrProp.name_ru, name_kk: this.agrProp.name_kk, name_en: this.agrProp.name_en, btn_name_ru: this.agrProp.btn_name_ru, btn_name_kk: this.agrProp.btn_name_kk, btn_name_en: this.agrProp.btn_name_en };
        for (const r of this.agrProp.operation) {
            this.curOper.push(this.setNameLang(r, 'code'));
        }
        for (const s of this.agrProp.stepForward) {
            const arr = this.statusLst.filter((item: any) => { return item.code === s.step_code; });
            if (arr.length > 0) { this.curStepForward.push(arr[0]); }
        }
        for (const s of this.agrProp.stepBack) {
            const arr = this.statusLst.filter((item: any) => { return item.code === s.step_code; });
            if (arr.length > 0) { this.curStepBack.push(arr[0]); }
        }
    }


    private async loadOper() {
        this.operBase = [];
        let response: any = [];
        try {
            response = await fetch('/api-py/dict-user-orerations/');
            if (response === null) {
                this.makeToast('danger', 'Ошибка загрузки операции пользователя', `${response}`);
                response = [];
            } else if (response.status === 200) {
                this.operBase = await response.json();
            } else {
                this.makeToast('danger', 'Ошибка загрузки операции пользователя', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            response = [];
            this.makeToast('danger', 'Ошибка загрузки операции пользователя', (error as Error).toString());
        }
    }

    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private closeEdit() {
        this.curStepForward = [];
        this.curStepBack = [];
        this.curOper = [];
        this.status = { id: -1, code: null, name_ru: '', name_kk: null, name_en: null };
        this.$emit('closeEdit');
    }

    private async save(close?: boolean) {
        const obj: any = this.status;
        if (!this.checkRequired()) { return; }
        this.saveDisabled = true;
        obj.code = parseInt(obj.code);
        this.status.code = obj.code;
        obj.shift = (this.addedCode !== null); // смещение кодов
        let result: any = await fetch('/api-py/save-agreement-status',
            {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(obj)
            });
        if (result.status === 200) {
            result = await result.json();
            if (result.result === 'success') {
                this.status.id = result.data.__data__.id;
            } else {
                this.makeToast('danger', 'Сохранение status', `Ошибка ${result.data}`);
                this.saveDisabled = false;
                return;
            }
        } else {
            this.makeToast('danger', 'Сохранение status', `Ошибка ${result.status} ${result.statusText}`);
            this.saveDisabled = false;
            return;
        }
        // ---- сохранить шаги
        const saveStep = [];
        for (const el of this.curStepForward) {
            saveStep.push({ mode_code: this.curMode.code, agr_code: this.status.code, step_code: el.code, step_type: 2 });
        }
        for (const el of this.curStepBack) {
            saveStep.push({ mode_code: this.curMode.code, agr_code: this.status.code, step_code: el.code, step_type: 1 });
        }
        const delStep = { code: this.status.code, mode_code: this.curMode.code };
        if (saveStep.length > 0) {
            await this.saveOperStep('/api-py/save-agreement-steps', saveStep, 'step');
        } else {
            await this.deleteStepOper('/api-py/del-agreement-steps', delStep);
        }

        // ---- сохранить операции пользователя
        if (this.curMode === null) {
            this.makeToast('danger', 'Сохранение status', 'Текущий статус не выбран');
            this.saveDisabled = false;
            return;
        }
        const saveOper = [];
        for (const oper of this.curOper) {
            saveOper.push({ mode_code: this.curMode.code, agr_code: this.status.code, operation_code: oper.code });
        }
        if (saveOper.length > 0) {
            await this.saveOperStep('/api-py/save-agreement-opers', saveOper, 'operation');
        } else {
            await this.deleteStepOper('/api-py/del-agreement-opers', delStep);
        }

        this.makeToast('success', 'Сохранение', 'Сохранение завершено!');
        this.$emit('update');
        if (close) { this.closeEdit(); }
        this.saveDisabled = false;
    }

    private async deleteStepOper(url: string, delObj: any) {
        let result: any = await fetch(url,
            {
                method: 'DELETE',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(delObj)
            });
        if (result.status === 200) {
            result = await result.json();
            if (result.result === 'error') {
                    this.makeToast('danger', 'Удаление шагов', `Ошибка ${result.data}`);
            }
        } else {
            this.makeToast('danger', 'Удаление шагов', `Ошибка ${result.status} ${result.statusText}`);
        }
    }

    private async saveOperStep(url: string, saveObj: any[], text: string) {
        let result: any = await fetch(url,
            {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(saveObj)
            });
        if (result.status === 200) {
            result = await result.json();
            for (const r of result) {
                if (r.result === 'error') {
                    this.makeToast('danger', `Сохранение ${text}`, `Ошибка ${r.data}`);
                }
            }
        } else {
            this.makeToast('danger', `Сохранение ${text}`, `Ошибка ${result.status} ${result.statusText}`);
        }
    }

    private checkRequired() { // проверка на обязательные поля
        if (this.status.code === null || parseInt(this.status.code).isNaN) {
            this.makeToast('warning', 'Сохранение', 'Введите код');
            return false;
        }
        if (this.status.name_ru === null || this.status.name_ru.trim().length === 0) {
            this.makeToast('warning', 'Сохранение', 'Введите наименование на русском');
            return false;
        }
        if (this.addedCode === null) {
            for (const el of this.statusLst) {
                if (el.code === parseInt(this.status.code)) {
                    if (this.status.id !== el.id) {
                        this.makeToast('warning', 'Сохранение', 'Данный код уже существует');
                        return false;
                    }
                    return true;
                }
            }
        }
        return true;
    }

    private setNameLang(obj: any, codeName?: any | null): any {
        let txt = obj['name_' + this.$i18n.locale];
        if (!txt) { txt = obj.name_ru; }
        if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
            txt = obj[codeName] + ' - ' + txt;
        }
        const el = Object.assign({}, obj);
        el.name = txt;
        return el;
    }

    private showMultiselectedTitle(arr: any[]) {
        const result: any[] = [];
        for (const el of arr) {
            result.push(el.name);
        }
        return result.join(', ');
    }
}

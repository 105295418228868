



























































































































import { Component, Vue } from 'vue-property-decorator';
import CAgreementEdit from '@/modules/settings/components/agreement-edit.vue';
@Component({
    components: {
        'c-agr-edit': CAgreementEdit
    }
})
export default class CAgreement extends Vue {
    private edit = false;
    private modeLst: any[] = [];
    private curMode: any = null;
    private agreemData: any[] = [];
    private statusLst: any[] = [];
    private addedCode: any | null = null; // если вставляется строка в середину
    // ---
    private editData: null | any = null;
    // --
    private withStep = true; // только с шагами
    private progress = 0;


    private async mounted() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        await this.loadModes();
        await this.loadData();
        this.$watch('curMode', function () { that.loadData(true); });
    }

    private async loadModes() {
        let response: any = [];
        try {
            response = await fetch('/api-py/get-all-agreement-mode/');
            if (response === null) {
                this.makeToast('danger', 'Ошибка загрузки режимов', `${response}`);
                response = [];
            } else if (response.status === 200) {
                response = await response.json();
            } else {
                this.makeToast('danger', 'Ошибка загрузки режимов', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            response = [];
            this.makeToast('danger', 'Ошибка загрузки режимов', (error as Error).toString());
        }
        this.modeLst = response;
        if (this.modeLst.length > 0) { this.curMode = this.modeLst[0]; }
    }

    private async loadData(update?: boolean) {
        let response: any = [];
        this.progress = 35;
        try {
            // eslint-disable-next-line @typescript-eslint/camelcase
            const param = encodeURI(JSON.stringify({ mode_code: this.curMode.code }));
            response = await fetch(`/api-py/get-all-agreement-status/${param}`);
            if (response === null) {
                this.makeToast('danger', 'Ошибка загрузки статусов', `${response}`);
                response = [];
            } else if (response.status === 200) {
                response = await response.json();
            } else {
                this.makeToast('danger', 'Ошибка загрузки статусов', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            response = [];
            this.makeToast('danger', 'Ошибка загрузки статусов', (error as Error).toString());
        }
        this.progress = 95;
        for (const el of response) {
            let tmpArr = [];
            for (const r of el.operation) { tmpArr.push(r.code); }
            el.operationStr = tmpArr.join('; ');
            tmpArr = [];
            for (const s of el.stepForward) { tmpArr.push(s.step_code); }
            el.stepForwardStr = tmpArr.join('; ');
            tmpArr = [];
            for (const s of el.stepBack) { tmpArr.push(s.step_code); }
            el.stepBackStr = tmpArr.join('; ');
        }
        if (update && this.editData !== null) {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < response.length; i++) {
                if (response[i].id === this.editData.id) {
                    this.editData = response[i];
                    break;
                }
            }
        }
        this.progress = 100;
        this.agreemData = response;
    }

    private get agreementFilt(): any {
        if (!this.withStep) {
            return this.agreemData;
        }
        return this.agreemData.filter((item: any) => {
            return item.stepBack.length > 0 || item.stepForward.length > 0;
        });
    }


    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private editClk(indx: number) {
        this.editData = this.agreementFilt[indx];
        this.edit = true;
    }

    // добавить новую запись
    private addClk() {
        // eslint-disable-next-line @typescript-eslint/camelcase
        this.editData = { id: -1, code: null, name_ru: '', name_kk: null, name_en: null };
        this.edit = true;
    }

    private addShift(indx: number) { // добавить со смещением
        this.editData = null;
        this.addedCode = this.agreementFilt[indx].code;
        this.edit = true;
    }

    private deleteRow(indx: number, shift: boolean) {
        let txt = 'Удалить запись?';
        txt = 'Удалить запись со смещением?';
        this.$bvModal.msgBoxConfirm(
            txt,
            {
                title: 'Подтверждение',
                size: 'lg',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Да',
                cancelTitle: 'Нет',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                if (value) { this.delete(indx, shift); }
            })
            .catch(error => {
                this.makeToast('danger', 'Ошибка удаления', error.toString());
            });
    }
    
    private async delete(indx: number, shift: boolean) {
        const delObj = Object.assign({ shift: shift, mode: this.curMode.code }, this.agreementFilt[indx]);
        let result: any = await fetch('/api-py/del-agreement-status',
            {
                method: 'DELETE',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(delObj)
            });
        if (result.status === 200) {
            result = await result.json();
            if (result.result === 'success') {
                await this.loadData();
                this.makeToast('success', 'Удаление', 'Запись удалена');
            } else if (result.result === 'error') {
                this.makeToast('danger', 'Удаление', `Ошибка ${result.data}`);
            }
        } else {
            this.makeToast('danger', 'Удаление', `Ошибка ${result.status} ${result.statusText}`);
        }
    }

    private closeEdit() {
        this.edit = false;
        this.editData = null;
        this.addedCode = null;
    }
}
